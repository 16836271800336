/** THIS APP REPRESENTS THE DEFAULT USER SIDE OF THE APPLICATION AND WHAT THEY SHOULD SEE **/

import "./Map.css";
import React, { useRef, useEffect, useState } from "react";
import * as maptilersdk from "@maptiler/sdk";
import "@maptiler/sdk/dist/maptiler-sdk.css";
import axios from "axios";
import { SignInButton } from "@clerk/clerk-react";

// main function, equipped with admin privileges
function MapAdmin({ propData }) {
  const mapContainer = useRef(null);
  const [navControls, setNavControls] = useState(
    new maptilersdk.MaptilerNavigationControl()
  );
  var map = useRef(null);
  const australia = { lng: 130.753, lat: -25.6844 };
  const [markers, setMarkers] = useState([]);

  // const data = dataset;
  maptilersdk.config.apiKey = process.env.REACT_APP_API_KEY_MAPTILER;

  // renders the map
  useEffect(() => {
    if (map.current) return; // stops map from intializing more than once

    map.current = new maptilersdk.Map({
      container: mapContainer.current,
      style: maptilersdk.MapStyle.TONER,
      center: [australia.lng, australia.lat],
      zoom: 3,
      maxBounds: [109.9694, -55.95665, 190.0016, -8.93711],
      maxZoom: 16,
      navigationControl: false,
      geolocateControl: false,
    });
    // adds map navigation controls
    map.current.addControl(navControls, "top-left");
    // map.current.addControl(geoControls, "top-left");

    map.current.on("error", function (error) {
      throw new Error("Could not load map.");
    });
  }, []);

  // renders markers from database server
  useEffect(() => {
    // checks for non-existent and if propdata already exists
    if (!propData) {
      return;
    }
    // prevents multiple markers from being rendered
    if (markers.length > 0) {
      return;
    }

    propData.data.forEach((point) => {
      // ignores inactive points
      if (point.properties.status === false) {
        return;
      }

      // creates marker div element and fills it
      const pointDiv = document.createElement("div");
      // popup styling
      const markerWidth = 40,
        markerHeight = 55,
        linearOffset = 25;
      const popupOffsets = {
        top: [0, 0],
        "top-left": [0, 0],
        "top-right": [0, 0],
        bottom: [0, -markerHeight],
        "bottom-left": [
          linearOffset,
          (markerHeight - markerWidth + linearOffset) * -1,
        ],
        "bottom-right": [
          -linearOffset,
          (markerHeight - markerWidth + linearOffset) * -1,
        ],
        left: [markerWidth, (markerHeight - markerWidth) * -1],
        right: [-markerWidth, (markerHeight - markerWidth) * -1],
      };
      const popup = new maptilersdk.Popup({ offset: popupOffsets });
      popup.setHTML(
        `<b>${point.properties.info}</b><br/>${point.properties.time}<br/>${point.properties.locationName}<br/><a target="_blank" href="${point.properties.url}">${point.properties.url}</a>`
      );

      let color = "#8634aa";
      pointDiv.id = "radio_marker";
      pointDiv.className = "marker";

      if (point.properties.type === "EVENT") {
        color = "#ffa600";
        pointDiv.id = "event_marker";
      }
      if (point.properties.type === "RECORD") {
        color = "#1C913E";
        pointDiv.id = "record_marker";
      }

      const newMarker = new maptilersdk.Marker({
        element: pointDiv,
        color: color,
      })
        .setLngLat(point.geometry.coordinates)
        .setPopup(popup)
        .addTo(map.current);

      // updates point coordinates after drag and drop
      newMarker.on("dragend", () => {
        const lng = parseFloat(newMarker.getLngLat().lng);
        const lat = parseFloat(newMarker.getLngLat().lat);
        axios.patch(
          `${process.env.REACT_APP_API_URL}/api/update/${point._id}`,
          {
            properties: {
              type: point.properties.type,
              locationName: point.properties.locationName,
              info: point.properties.info,
              time: point.properties.time,
              status: point.properties.status,
            },
            geometry: {
              geometryType: point.geometry.geometryType,
              coordinates: [lng, lat],
            },
          }
        );
      });
      setMarkers((markers) => [...markers, newMarker]);
    });
  }, [propData]);

  return (
    <div className="map-wrap">
      <div ref={mapContainer} className="map" id="map">
        <SignInButton mode="modal">
          <button className="signInOut-btn"></button>
        </SignInButton>
      </div>
      <div className="mapbox"></div>
      {/* <a
        href="https://www.flaticon.com/free-icons/font-size"
        title="font size icons"
      >
        Font size icons created by Md Tanvirul Haque - Flaticon
      </a>
      <br />
      <a href="https://www.flaticon.com/free-icons/guitar" title="guitar icons">
        Guitar icons created by Freepik - Flaticon
      </a> */}
    </div>
  );
}

export default MapAdmin;
