/** THIS APP REPRESENTS THE CUSTOMER-SIDE OF THE APPLICATION AND WHAT THEY SHOULD SEE **/

import "./Map.css";
import React, { useRef, useEffect, useState } from "react";
import * as maptilersdk from "@maptiler/sdk";
import "@maptiler/sdk/dist/maptiler-sdk.css";
import axios from "axios";
import * as DOMPurify from "dompurify";
import toast, { Toaster } from "react-hot-toast";
import Modal from "react-modal";
import { SignOutButton } from "@clerk/clerk-react";

// enables toast messages
function newToast(message) {
  return toast(message);
}

// styling for modal
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    height: "90vh",
    overflow: "auto",
  },
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement(document.createElement("div"));

// main function, equipped with admin privileges
function MapAdmin({ propData, isAdmin, setPropDataFunc }) {
  const mapContainer = useRef(null);
  const [mapData, setMapData] = useState(propData);
  const [navControls, setNavControls] = useState(
    new maptilersdk.MaptilerNavigationControl()
  );
  const [geoControls, setGeoControls] = useState(
    new maptilersdk.MaptilerGeolocateControl()
  );
  var map = useRef(null);
  const australia = { lng: 130.753, lat: -25.6844 };
  const [coordinateData, setCoordinateData] = useState();
  const [staticCoordinateData, setStaticCoordinateData] = useState([
    134.753, -26.6844,
  ]);
  const [draggable, setDraggable] = useState(false);
  const [markers, setMarkers] = useState([]);
  const [editModeEnabled, setEditModeEnabled] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [currentMarker, setCurrentMarker] = useState({});
  const [currentLocation, setCurrentLocation] = useState("");
  const [currentInfo, setcurrentInfo] = useState("");
  const [currentTime, setCurrentTime] = useState("");
  const [currentURL, setCurrentURL] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [markerStatus, setMarkerStatus] = useState();
  const [modalLayout, setModalLayout] = useState(<></>);
  let subtitle;

  // const data = dataset;
  const ref = useRef(null);
  // const [showModal, setShowModal] = useState(false);
  maptilersdk.config.apiKey = process.env.REACT_APP_API_KEY_MAPTILER;

  // renders the map
  useEffect(() => {
    if (map.current) return; // stops map from intializing more than once

    map.current = new maptilersdk.Map({
      container: mapContainer.current,
      // style: maptilersdk.MapStyle.TONER,
      // link for custom inversed toner map
      style: `https://api.maptiler.com/maps/b853e5e9-6f98-4d29-ab56-2d9d5cf7b062/style.json?key=${process.env.REACT_APP_API_KEY_MAPTILER}`,
      center: [australia.lng, australia.lat],
      zoom: 3,
      maxBounds: [109.9694, -48.95665, 190.0016, -8.93711],
      maxZoom: 16,
      navigationControl: false,
      geolocateControl: false,
    });
    // adds map navigation controls
    map.current.addControl(navControls, "top-left");
    // map.current.addControl(geoControls, "top-left");

    // coordinate data of cursor at all times
    map.current.on("mousemove", (event) => {
      // shows x/y coordinates
      // JSON.stringify(event.point);
      // gets coordinate of mouse position on map
      // setCoordinateData(JSON.stringify(event.lngLat));
    });
    // gets coordinate data of click
    map.current.on("click", (event) => {
      setStaticCoordinateData([
        JSON.parse(event.lngLat.lng),
        JSON.parse(event.lngLat.lat),
      ]);
      // document.getElementById("lngInput").value = event.lngLat.lat;
      // document.getElementById("latInput").value = event.lngLat.lng;
    });
    map.current.on("error", function (error) {
      throw new Error("Could not load map.");
    });
  }, []);

  // default function
  function openModal() {
    setIsOpen(true);
  }

  // default function
  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    map.current.on("load", () => {
      map.current.navigationControl = false;
      map.current.geolocateControl = false;
    });
  }

  // default function
  function closeModal() {
    setIsOpen(false);
  }

  // renders markers from database server
  useEffect(() => {
    // checks for non-existent and if propdata already exists
    if (!propData) {
      console.log("no data");
      return;
    }
    // prevents multiple markers from being rendered
    if (markers.length > 0) {
      return;
    }

    propData.data.forEach((point) => {
      // creates marker div element and fills it
      const pointDiv = document.createElement("div");
      // popup styling
      const markerWidth = 40,
        markerHeight = 55,
        linearOffset = 25;
      const popupOffsets = {
        top: [0, 0],
        "top-left": [0, 0],
        "top-right": [0, 0],
        bottom: [0, -markerHeight],
        "bottom-left": [
          linearOffset,
          (markerHeight - markerWidth + linearOffset) * -1,
        ],
        "bottom-right": [
          -linearOffset,
          (markerHeight - markerWidth + linearOffset) * -1,
        ],
        left: [markerWidth, (markerHeight - markerWidth) * -1],
        right: [-markerWidth, (markerHeight - markerWidth) * -1],
      };
      const popup = new maptilersdk.Popup({ offset: popupOffsets });
      popup.setHTML(`<b>${point.properties.info}</b><br/>${point.properties.time}<br/>${point.properties.locationName}<br/><a target="_blank" href="${point.properties.url}">${point.properties.url}</a><br/>
         ${point._id}`);
      // genPopupHTML(point.properties)

      let color = "#8634aa";
      pointDiv.id = "radio_marker";
      pointDiv.className = "marker";

      if (point.properties.type === "EVENT") {
        color = "#ffa600";
        pointDiv.id = "event_marker";
      }
      if (point.properties.type === "RECORD") {
        color = "#1C913E";
        pointDiv.id = "record_marker";
      }
      if (point.properties.status == false) {
        pointDiv.id += "-inactive";
      }

      const newMarker = new maptilersdk.Marker({
        element: pointDiv,
        color: color,
      })
        .setLngLat(point.geometry.coordinates)
        .setPopup(popup)
        .addTo(map.current)
        .setDraggable(draggable);

      // enables data modification of markers
      if (editModeEnabled) {
        newMarker.getElement().addEventListener("click", () => {
          console.log(point);
          setCurrentMarker(point);
          setCurrentLocation(point.properties.locationName);
          setcurrentInfo(point.properties.info);
          setCurrentTime(point.properties.time);
          setCurrentURL(point.properties.url);
          setSelectedType(point.properties.type);
          setMarkerStatus(point.properties.status);
          setIsOpen(true);
        });
      }

      // updates point coordinates after drag and drop
      newMarker.on("dragend", () => {
        const lng = parseFloat(newMarker.getLngLat().lng);
        const lat = parseFloat(newMarker.getLngLat().lat);
        axios.patch(
          `${process.env.REACT_APP_API_URL}/api/update/${point._id}`,
          {
            properties: {
              type: point.properties.type,
              locationName: point.properties.locationName,
              info: point.properties.info,
              time: point.properties.time,
              status: point.properties.status,
            },
            geometry: {
              geometryType: point.geometry.geometryType,
              coordinates: [lng, lat],
            },
          }
        );
      });
      setMarkers((markers) => [...markers, newMarker]);
    });
  }, [propData]);

  // refreshes markers on map
  // is needed for seeing changes as they occur in db
  const refreshMarkers = () => {
    //deletes all existing markers
    markers.map((marker) => {
      marker.remove();
    });
    setMarkers([]);
    // triggers refetch of data in App page
    setPropDataFunc(null);
  };

  const newMarker = async () => {
    console.log(staticCoordinateData);
    // sends data to database via express server
    try {
      const response = await axios
        .post(`${process.env.REACT_APP_API_URL}/api/post`, {
          properties: {
            type: "RADIO",
            locationName: "location",
            info: "information",
            time: "time",
            status: true,
            url: "url-link",
          },
          geometry: {
            geometryType: "Point",
            coordinates: [staticCoordinateData[0], staticCoordinateData[1]],
          },
        })
        .then((response) => {})
        .catch((error) => {
          alert(
            "Could not post new marker data. All fields must be filled in!"
          );
        });
    } catch (error) {
      console.log(error);
      alert("please select a ");
    }

    refreshMarkers();
  };

  // switches between client and administrative mode
  const editMode = (e) => {
    var editBtn = document.querySelector("#editBtn");
    var editBtn_text = document.querySelector("#editBtn_text");
    // entering edit mode
    if (editBtn.classList == "editBtn") {
      setEditModeEnabled(true);
      editBtn.classList.replace("editBtn", "editBtn-active");
      editBtn_text.innerText = "Edit Mode: On";
      // makes markers draggable
      setDraggable(true);
      refreshMarkers();
      newToast("Markers can now be dragged!");
    } else {
      setEditModeEnabled(false);

      editBtn.classList.replace("editBtn-active", "editBtn");
      editBtn_text.innerText = "Edit Mode: Off";
      setDraggable(false);
      refreshMarkers();
    }
  };

  // submits a request to update marker data
  const submitChanges = async (e) => {
    // prevents page refresh
    e.preventDefault();
    // gets selected marker type
    let type = "",
      types = document.getElementsByName("markerType-edit");
    for (var i = 0; i < types.length; i++) {
      if (types[i].checked) {
        type = types[i].value;
      }
    }

    let location;
    let info;
    let time;
    let url;

    // input sanitisation
    try {
      // following code sets the values based on whether they were modified: if not modified, use pre-exisitng value
      location = document.getElementById("locationName-edit").value
        ? document.getElementById("locationName-edit").value
        : currentMarker.properties.locationName;
      info = document.getElementById("info-edit").value
        ? document.getElementById("info-edit").value
        : currentMarker.properties.info;
      time = document.getElementById("time-edit").value
        ? document.getElementById("time-edit").value
        : currentMarker.properties.time;
      url = document.getElementById("url-edit").value
        ? document.getElementById("url-edit").value
        : currentMarker.properties.url;

      // JSON sanitisation
      try {
        if (JSON.parse(time)) {
          time = "";
        }
      } catch (e) {}

      try {
        if (JSON.parse(location)) {
          // alert("May not input data in JSON format!");
          location = "";
        }
      } catch (e) {}

      try {
        if (JSON.parse(info)) {
          // alert("May not input data in JSON format!");
          info = "";
        }
      } catch (e) {}

      try {
        if (JSON.parse(url)) {
          // alert("May not input data in JSON format!");
          url = "";
        }
      } catch (e) {}
      // Storage sanisation handled in API/backend

      // XSS Sanitisation, will remove special chars
      location = DOMPurify.sanitize(location);
      info = DOMPurify.sanitize(info);
      time = DOMPurify.sanitize(time);
      url = DOMPurify.sanitize(url);
    } catch (error) {
      // console.log(error);
    }

    // sends marker updates
    await axios.patch(
      `${process.env.REACT_APP_API_URL}/api/update/${currentMarker._id}`,
      {
        properties: {
          type: type,
          locationName: location,
          info: info,
          time: time,
          status: markerStatus,
          url: url,
        },
      }
    );
    refreshMarkers();
    closeModal();
  };

  const deleteMarker = async (e) => {
    //prevents page refresh
    e.preventDefault();
    await axios.delete(
      `${process.env.REACT_APP_API_URL}/api/delete/${currentMarker._id}`
    );
    refreshMarkers();
    // collapses editing modal
    closeModal();
  };

  // changes active/inactive status of selected marker
  const changeStatus = async (e) => {
    // prevents page refresh
    e.preventDefault();
    setMarkerStatus(!markerStatus);
    // sets id for styling of status button
    const statusElement = document.getElementsByClassName("btn-status");
    markerStatus
      ? (statusElement[0].id = "status-inactive")
      : (statusElement[0].id = "status-active");
  };

  return (
    <div className="map-wrap">
      <div ref={mapContainer} className="map" id="map">
        <SignOutButton>
          <button className="signInOut-btn"></button>
        </SignOutButton>
      </div>
      <div className="mapbox">
        <div className="adminTools">
          <div className="newBtn" id="newBtn" onClick={newMarker}>
            <p id="newBtn_text">New Marker</p>
            <Toaster position="bottom-center" />
          </div>
          <div className="editBtn" id="editBtn" onClick={editMode}>
            <p id="editBtn_text">Edit Mode: Off</p>
            <Toaster position="bottom-center" />
          </div>
          <div className="coordinateLabels">
            {/* {!staticCoordinateData
              ? "Click for coordinates"
              : staticCoordinateData} */}
          </div>
        </div>

        {/* Edit Marker Modal */}
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="markerModal"
        >
          <div className="modal-container">
            <form
              className="marker-form"
              id="editMarker-form"
              onsubmit="return false;"
              autoComplete="off"
            >
              <h2
                className="marker-form-title"
                ref={(_subtitle) => (subtitle = _subtitle)}
              >
                Edit Marker
              </h2>
              <label for="radioBtns">Marker Type:</label>
              <div id="radioBtns">
                <label for="radio">
                  <input
                    type="radio"
                    id="radio"
                    name="markerType-edit"
                    value="RADIO"
                    defaultChecked={selectedType === "RADIO"}
                  />
                  Radio
                </label>

                <br />

                <label for="event">
                  <input
                    type="radio"
                    id="event"
                    name="markerType-edit"
                    value="EVENT"
                    defaultChecked={selectedType === "EVENT"}
                  />
                  Event
                </label>

                <br />
                <label for="record">
                  <input
                    type="radio"
                    id="record"
                    name="markerType-edit"
                    value="RECORD"
                    defaultChecked={selectedType === "RECORD"}
                  />
                  Record
                </label>
              </div>

              <label id="frequencyLabel" for="locationName">
                Frequency/Location:{" "}
              </label>
              <div className="input-field-container">
                <input
                  type="text"
                  id="locationName-edit"
                  className="input-field"
                  placeholder={currentLocation}
                />
              </div>

              <label for="time">Time: </label>
              <div className="input-field-container">
                <input
                  type="text"
                  id="time-edit"
                  className="input-field"
                  placeholder={currentTime}
                />
              </div>

              <label for="info">Name/Information: </label>
              <div className="input-field-container">
                <input
                  type="text"
                  id="info-edit"
                  className="input-field"
                  placeholder={currentInfo}
                />
              </div>

              <label for="url">URL: </label>
              <div className="input-field-container">
                <input
                  type="text"
                  id="url-edit"
                  className="input-field"
                  placeholder={currentURL}
                />
              </div>

              <div className="buttons-container">
                <button
                  className="btn-status"
                  id={markerStatus ? "status-active" : "status-inactive"}
                  style={{ width: "80%" }}
                  onClick={changeStatus}
                >
                  Status: {markerStatus ? "On" : "Off"}
                </button>

                <button
                  type="submit"
                  className="btn"
                  style={{ width: "80%" }}
                  onClick={submitChanges}
                >
                  Save Changes
                </button>

                <button
                  className="btn"
                  style={{ width: "80%" }}
                  id="editMarkerDelete-btn"
                  onClick={deleteMarker}
                >
                  Delete Marker
                </button>

                <button
                  className="btn"
                  style={{ width: "80%" }}
                  id="editMarkerCancel-btn"
                  onClick={closeModal}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </Modal>
      </div>
      <div>
        <img src="" alt="" />
      </div>

      {/* <a
        href="https://www.flaticon.com/free-icons/font-size"
        title="font size icons"
      >
        Font size icons created by Md Tanvirul Haque - Flaticon
      </a>
      <br />
      <a href="https://www.flaticon.com/free-icons/guitar" title="guitar icons">
        Guitar icons created by Freepik - Flaticon
      </a> */}
      {/* <a href="https://www.flaticon.com/free-icons/music-and-multimedia" title="music and multimedia icons">Music and multimedia icons created by Freepik - Flaticon</a> */}
    </div>
  );
}

export default MapAdmin;
