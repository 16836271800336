import { useEffect, useState } from "react";
import MapAdmin from "./MapAdmin";
import axios from "axios";
import MapClient from "./MapClient";
import { SignedIn, SignedOut } from "@clerk/clerk-react";

function App() {
  const [data, setData] = useState(null);
  // fetches data once
  useEffect(() => {
    // prevents refetching if no data
    if (data) {
      return;
    }
    axios
      // for local development
      // .get("http://localhost:8000/api/get")
      // for frontend/backend ec2
      .get(`${process.env.REACT_APP_API_URL}/api/get`)
      .then((response) => {
        // console.log("App level fetch: ", response);
        setData(response);
      })
      .catch((error) => {
        alert("Map markers could not load, please refresh page");
      });
    console.log(data);
  }, [data]);

  return (
    <div className="App" id="app">
      <SignedOut>
        <MapClient propData={data} setPropDataFunc={setData} />
      </SignedOut>
      <SignedIn>
        <MapAdmin propData={data} setPropDataFunc={setData} />
      </SignedIn>
    </div>
  );
}

export default App;
